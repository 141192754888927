module.exports = [{
      plugin: require('../node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ffad00","minimum":0.1,"trickleSpeed":100,"showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-32PS5MLMWX","cookieName":"statisticsCookiesAccepted","consentCookieName":"marketingCookiesAccepted","dataLayerName":"dataLayer","secondaryTrackingId":"G-32PS5MLMWX"},"facebookPixel":{"pixelId":"1904854636485992","cookieName":"statisticsCookiesAccepted"},"linkedin":{"trackingId":"671090","cookieName":"statisticsCookiesAccepted","nonce":"LSY_r4nD0m"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
