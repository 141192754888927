exports.components = {
  "component---src-pages-consulting-404-js": () => import("./../../../src/pages/consulting/404.js" /* webpackChunkName: "component---src-pages-consulting-404-js" */),
  "component---src-pages-consulting-file-not-found-js": () => import("./../../../src/pages/consulting/file_not_found.js" /* webpackChunkName: "component---src-pages-consulting-file-not-found-js" */),
  "component---src-pages-develop-startpage-js": () => import("./../../../src/pages/develop/startpage.js" /* webpackChunkName: "component---src-pages-develop-startpage-js" */),
  "component---src-pages-ie-not-supported-js": () => import("./../../../src/pages/ie_not_supported.js" /* webpackChunkName: "component---src-pages-ie-not-supported-js" */),
  "component---templates-article-js": () => import("./../../../templates/article.js" /* webpackChunkName: "component---templates-article-js" */),
  "component---templates-full-screen-gallery-js": () => import("./../../../templates/fullScreenGallery.js" /* webpackChunkName: "component---templates-full-screen-gallery-js" */),
  "component---templates-gallery-js": () => import("./../../../templates/gallery.js" /* webpackChunkName: "component---templates-gallery-js" */),
  "component---templates-news-room-main-page-js": () => import("./../../../templates/newsRoomMainPage.js" /* webpackChunkName: "component---templates-news-room-main-page-js" */),
  "component---templates-page-js": () => import("./../../../templates/page.js" /* webpackChunkName: "component---templates-page-js" */),
  "component---templates-product-js": () => import("./../../../templates/product.js" /* webpackChunkName: "component---templates-product-js" */),
  "component---templates-sitemap-page-js": () => import("./../../../templates/sitemapPage.js" /* webpackChunkName: "component---templates-sitemap-page-js" */),
  "component---templates-start-page-js": () => import("./../../../templates/startPage.js" /* webpackChunkName: "component---templates-start-page-js" */),
  "component---templates-videogallery-js": () => import("./../../../templates/videogallery.js" /* webpackChunkName: "component---templates-videogallery-js" */),
  "slice---src-slices-footer-footer-js": () => import("./../../../src/slices/Footer/Footer.js" /* webpackChunkName: "slice---src-slices-footer-footer-js" */),
  "slice---src-slices-header-header-js": () => import("./../../../src/slices/Header/Header.js" /* webpackChunkName: "slice---src-slices-header-header-js" */)
}

